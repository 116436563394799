import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import Icon from '../Common/Icon';
import { updateActionSheet, updateActionSheetData } from '../../store/auth';
import { ACTIONSHEET } from '../../constants/actionSheets';
import FormattedTime from '../Common/DateTimePicker/FormattedTime';
import Button from '../Common/Button';
import { DateTime, Duration } from 'luxon';
import { stopTimeRecord } from '../../api/timeTracker';
import useInterval from '../../hooks/useInterval';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
const WORK_TYPE_IMAGES = {
	work: 'work',
	pause: 'pause',
	sickLeave: 'face-thermometer',
	vacation: 'luggage',
	mandatoryPause: 'pause',
	reducedOvertime: 'business-time'
};
const TIME_SECOND = 1000;
/**
 * @param timeRecord is an element of an array which includes a time record
 */
const TimeTrackerOverviewItem = ({ timeRecord, stopClicked }) => {
	const dispatch = useDispatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const selectedOfficeTimezone = selectedOffice?.timezone;
	const selectedOfficeId = selectedOffice?._id;
	const timerName = timeRecord?.name;
	const startTime = timeRecord?.start?.time;
	const endTime = timeRecord?.end?.time;
	const isMandatoryPause = timerName === 'mandatoryPause';
	const timerOngoing = endTime === undefined;
	const showStopButton = !isMandatoryPause && timerOngoing;
	const showMenu = !showStopButton && !timerOngoing;
	const workTypeImage = WORK_TYPE_IMAGES[timerName];
	const [duration, setDuration] = useState(0);

	useInterval(() => {
		if (timerOngoing) {
			let totalDuration = Duration.fromObject({ hours: 0 });

			const start = DateTime.fromISO(startTime);
			const end = DateTime.now();
			const duration = end.diff(start);
			totalDuration = totalDuration.plus(duration);

			setDuration(totalDuration);
		}
	}, 1000);

	const handleStopButton = () => {
		stopTimeRecord({ office: selectedOfficeId })
			.then((updatedTimeRecord) => stopClicked(updatedTimeRecord.data))
			.catch((err) => console.log('An error occured while pressing stop:', err));
	};
	const openActionSheet = (e) => {
		e.stopPropagation();
		dispatch(updateActionSheet(ACTIONSHEET.TIME_TRACK_EDIT_MENU));
		dispatch(updateActionSheetData(timeRecord));
	};

	return (
		<div className="time-tracks-item">
			<div className="time-tracks-item-type">
				<div className="header">
					{workTypeImage === 'face-thermometer' && <SickOutlinedIcon />}
					{workTypeImage === 'luggage' && <LuggageOutlinedIcon />}
					{workTypeImage !== 'face-thermometer' && workTypeImage !== 'luggage' && (
						<Icon iconName={workTypeImage} cssClass="icon-black" />
					)}
					<div className="work-type-container">
						<p className="work-type">{t('timeTracker.options.' + timerName)}</p>
						<p className="from-to-time">
							<span>{t('common.dateTimePicker.from')}:</span>
							<FormattedTime time={startTime} timezone={selectedOfficeTimezone} />
						</p>
						{timerOngoing ? (
							<p className="ongoing-time">{Duration.fromMillis(duration)?.toFormat('hh:mm:ss')}</p>
						) : (
							<p className="from-to-time">
								<span>{t('common.dateTimePicker.to')}:</span>
								{<FormattedTime time={endTime || DateTime.now()} timezone={selectedOfficeTimezone} />}
							</p>
						)}
					</div>
				</div>
				{showStopButton ? (
					<Icon direct={true} iconName="fa-regular fa-circle-stop" cssClass="icon-red" clickHandler={handleStopButton} />
				) : showMenu ? (
					<Icon iconName="ellipsis" cssClass="icon-grey" clickHandler={openActionSheet} />
				) : null}
			</div>
		</div>
	);
};

export default TimeTrackerOverviewItem;
